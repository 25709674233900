import React, { Component, useEffect, useState } from 'react'
import { useHistory } from "react-router"

//css
import './styles.css'

//Service
import rest from '../../service/rest'

//Components
import LoadingBar from '../../components/loadingBar'
import AlertMessage from '../../components/alertMessage'
import FieldTextOpacity from '../../components/fieldTextOpacity'
import ButtonRectangle from '../../components/buttonRectangle'
import Footer from '../../components/footer'

//assets
//import jjWhiteLogo from '../../imgs/jj_white_logo.png'
import logo1 from '../../imgs/logotipoIndaialEngenharia_1.png'

const Login = () => {
    let history = useHistory()
    const [isOnline, setOnline] = useState(window.navigator.onLine)
    const [isLoading, setIsLoading] = useState(true)
    const [alertMessage, setAlertMessage] = useState({
        type: '',
        msg: '',
        show: false
    })

    const pressEnter = async (e) => {
        if (e.key == 'Enter')
            signIn()
    }

    const findCitys = async () => {
        const response = await rest.get('/city/v1/findAll')
        await localStorage.setItem('@lighthouse_jjeletrica:citys', JSON.stringify(response.data))
        await localStorage.setItem('@lighthouse_jjeletrica:cityForFilter', '-1')
        console.log(response.data)
    }

    const signIn = async () => {
        let username = document.getElementById("edtUserName").value
        let password = document.getElementById("edtPassword").value
        if (username == '' || password == '') {
            setAlertMessage({
                type: 'error',
                msg: 'Favor informar usuário e senha',
                show: true
            })
        } else {
            try {
                setIsLoading(true)
                setAlertMessage({
                    type: '',
                    msg: '',
                    show: false
                })

                const response = await rest.post('/login/v1/login', {
                    username: username,
                    password: password
                })

                // const token = response.headers.authorization;
                const user = response.data
                setIsLoading(false)
                console.log('const user = response.data...')
                console.log(response.data)
                // await localStorage.setItem('@lighthouse_jjeletrica:token', token)
                await localStorage.setItem('@lighthouse_jjeletrica:user', JSON.stringify(response.data))
                if (user && user.token) {
                    history.push('/chamados')
                } else {
                    setAlertMessage({
                        type: 'error',
                        msg: 'Usuário ou senha inválidos',
                        show: true
                    })
                }
            } catch (err) {
                setAlertMessage({
                    type: 'error',
                    msg: 'Falha na autenticação',
                    show: true
                })
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        findCitys()
        setIsLoading(false)
    }, [])

    return (
        <div>
            {
                <LoadingBar show={isLoading} />
            }
            <div className="full mainBackgroundGrad loginBackground">
                <div className="container jjWhiteLogoImgBackgroundx">
                    <div className="row">
                        <div className="notShowUntilMedium col-12 col-md-6 full center toBottom">
                            <div className="bottom25vh padding30">
                                <img className="img-fluid" src={logo1} />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 full center toBottom ">
                            <div className="bottom25vh center">
                                <div className="row col-12 col-sm-8">
                                    <div className="col-12 text-centerx whiteFont">
                                        <h1 className="title">Login</h1>
                                        <div className="mainDegLine"></div>
                                    </div>
                                    <div className="col-12 top20">
                                        {
                                            alertMessage.show ?
                                                <AlertMessage msg={alertMessage.msg} type={alertMessage.type} />
                                                :
                                                <div></div>
                                        }
                                    </div>
                                    <div className="col-12">
                                        <FieldTextOpacity id="edtUserName" name="edtUserName" placeholder="Usuário" />
                                    </div>
                                    <div className="col-12 top10" onKeyDown={e => pressEnter(e)}>
                                        <FieldTextOpacity id="edtPassword" name="edtPassword" type="password" placeholder="Senha" />
                                    </div>
                                    <div className="col-12 centerx top60 bottom30">
                                        <div onClick={e => signIn()}>
                                            <ButtonRectangle label="LOGIN" />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row boxBackground boxShadow padding30 col-12 col-sm-6">
                                    <div className="col-12 text-center titleBox1 whiteFont">
                                        <h1 className="title padding30">Login</h1>
                                    </div>
                                    <div className="col-12">
                                        {
                                            alertMessage.show ?
                                                <AlertMessage msg={alertMessage.msg} type={alertMessage.type} />
                                                :
                                                <div></div>
                                        }
                                    </div>
                                    <div className="col-12">
                                        <FieldTextOpacity id="edtUserName" name="edtUserName" placeholder="Usuário" />
                                    </div>
                                    <div className="col-12 top10" onKeyDown={e => pressEnter(e)}>
                                        <FieldTextOpacity id="edtPassword" name="edtPassword" type="password" placeholder="Senha" />
                                    </div>
                                    <div className="col-12 centerx top40 bottom30">
                                        <div onClick={e => signIn()}>
                                            <ButtonRectangle label="LOGIN" />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default Login