import React, { Component, useState, useEffect } from 'react';

//import logoImg from '../../imgs/jj_logo.png'
import logo1 from '../../imgs/logotipoIndaialEngenharia_1.png'

//Service
import rest from '../../service/rest'

const MenuBar = () => {
    const [level, setLevel] = useState(0)
    const [selectedCityID, setSelectedCityID] = useState(0)
    const [citys, setCitys] = useState([])

    const signOut = async () => {
        try {
            //Logout
            // await localStorage.removeItem('@lighthouse_jjeletrica:token', '');
            await localStorage.removeItem('@lighthouse_jjeletrica:user', '')
            window.location.reload(false)

            //Temporário
            window.location.pathname = "/login"

        } catch (err) {
        }
    }

    const saveCurrentCity = async (id) => {
        localStorage.setItem('@lighthouse_jjeletrica:cityForFilter', id)
    }

    const saveSelectedCity = async (id) => {
        await saveCurrentCity(id)
        //Quando selecionar uma cidade, atualiza a página
        window.location.reload(true)
        // this.setState({
        //     ...this.state,
        //     selectedCityID: id
        // })
    }

    const findCitys = async () => {
        // const response = await rest.get('/city/v1/findAllView')
        // setCitys(response.data)
        const cityFromStorage = await localStorage.getItem('@lighthouse_jjeletrica:citys')
        const citys = JSON.parse(cityFromStorage)
        console.log("cityFromStorage..cityFromStorage...cityFromStorage..cityFromStorage")
        console.log(citys)
        if (cityFromStorage != "" && cityFromStorage != null) {
            setCitys(citys)
        } else {
            setCitys([])
        }
    }

    useEffect(() => {
        const asyncStart = async () => {
            const cityForFilter = await localStorage.getItem('@lighthouse_jjeletrica:cityForFilter')
            const userFromStorage = await localStorage.getItem('@lighthouse_jjeletrica:user')
            const user = JSON.parse(userFromStorage)
            // const user = localStorage.getItem('@lighthouse_jjeletrica:user')
            if (user) {
                setLevel(user.level)
                setSelectedCityID(cityForFilter)
            }
            await findCitys()
        }
        asyncStart()
    }, [])

    return (
        <div>
            <nav id="menuFixed" className="navbar fixed-top navbar-expand-lg nav-light menu_nav_barx secondColorBK padding20 boxShadow delay">
                <a className="navbar-brand" href="#top"><img src={logo1} className="logoImg" /></a>
                <button className="navbar-toggler blueMask01" type="button" data-toggle="collapse" data-target="#menuBar" aria-controls="menuBar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon center topLess1">-</span>
                </button>

                <div className="collapse navbar-collapse" id="menuBar">
                    <ul className="navbar-nav ml-auto">
                        {
                            level == 3 &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="boxSelect">
                                        {
                                            <div id='boxSelectCityId' className='borderGray'>
                                                {/* <label for='' id='' className="labelSelect">Unidade de Medida*</label><br /> */}
                                                <select name="city" id="cityId" className="selectOptionsBox borderNone" onChange={(e) => saveSelectedCity(e.target.value)}>
                                                    {
                                                        selectedCityID == -1 ?
                                                            <option value='-1' className="selectOption blackFont" selected>Todas as Cidade</option>
                                                            :
                                                            <option value='-1' className="selectOption blackFont">Todas as Cidade</option>
                                                    }

                                                    {
                                                        selectedCityID == 0 ?
                                                            <option value='0' className="selectOption blackFont" selected>Geral</option>
                                                            :
                                                            <option value='0' className="selectOption blackFont">Geral</option>
                                                    }

                                                    {
                                                        citys &&
                                                        citys.map((item, k) => {
                                                            return (
                                                                item.id == selectedCityID ?
                                                                    <option key={k} value={item.id} className="selectOption blackFont" selected>{item.name}</option>
                                                                    :
                                                                    <option key={k} value={item.id} className="selectOption blackFont">{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {/* <li className="nav-item active menu_options">
                                <a className="nav-link" href="/home">Inicio</a>
                            </li> */}
                        <li className="nav-item active menu_options">
                            <a className="nav-link" href="/chamados">Chamados</a>
                        </li>
                        {/* <li className="nav-item active menu_options">
                                <a className="nav-link" href="/material ">Materiais</a>
                            </li> */}
                        <li className="nav-item dropdown menu_options">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Materiais
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                {
                                level == 3 &&
                                <>
                                    <a className="nav-link" href="/materialGroup ">Grupos</a>
                                    <a className="nav-link" href="/materialSubGroup ">SubGrupos</a>
                                </>
                                }
                                <a className="nav-link" href="/material ">Materiais</a>
                                {
                                level == 3 &&
                                    <a className="nav-link" href="/materialgrupos ">Materiais e Grupos</a>
                                }
                            </div>
                        </li>
                        {/* <li className="nav-item active menu_options">
                                <a className="nav-link" href="/pdfrelatorio ">Relatorio Atendimentos</a>
                            </li> */}
                        <li className="nav-item active menu_options">
                            <a className="nav-link" target='_blank' href="/pdfrelatorio ">Relatorio Atendimentos</a>
                        </li>
                        {
                            level == 3 &&
                            <>
                                {/* <li className="nav-item active menu_options">
                                        <a className="nav-link" href="/material ">Materiais</a>
                                    </li> */}
                                <li className="nav-item active menu_options">
                                    <a className="nav-link" href="/usuarios">Acessos</a>
                                </li>
                                <li className="nav-item active menu_options">
                                    <a className="nav-link" href="/cidades">Cidades</a>
                                </li>
                                <li className="nav-item active menu_options">
                                    <a className="nav-link" href="/problema">Problemas</a>
                                </li>
                            </>
                        }


                        <li className="nav-item dropdown menu_options">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Opções
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" onClick={e => signOut()}>Sair</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>

    )
}

export default MenuBar